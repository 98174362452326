import { z } from 'zod';
import { ZObjectId } from '../objectid';
import { ZDateString } from '../date';

export const ZMetric = z.enum([
  'blood_pressure_systole',
  'blood_pressure_diastole',
  'pulse_rate',
  'smoker_status',
  'smoking_year_quit',
  'smoke_exposure',
  'smoking_cessation_advice',
  'weight',
  'height',
  'bmi',
  'alcohol_units_consumed_per_week_observable_entity',
  'level_of_physical_activity_observable_entity',
  'additional_note',
  'confidence_managing_condition',
  'nicotine_vaper_status',
  'goal',
  'diet',
  'low_salt_diet_advice_given',
  'excess_caffeine_intake',
  'caffeine_intake_notes',
  'does_home_glucose_monitoring',
  'glucose_readings_below_4mmol',
  'glucose_readings_below_2p8mmol',
  'experienced_hypos',
  'symptoms_during_hypos',
  'times_needed_help_during_hypos',
  'date_last_hypo',
  'blood_sugar_to_treat_hypo',
  'num_times_in_week_blood_sugar_less_than_4',
  'eat_drink_to_treat_hypo',
  'check_blood_sugar_before_driving',
  'fasting_at_time_of_hypo',
  'asthma_daily_life',
  'asthma_shortness_of_breath',
  'asthma_symptoms',
  'asthma_inhaler_medication',
  'asthma_control',
  'asthma_triggers',
  'asthma_exacerbations',
  'asthma_medication_understanding',
  'asthma_medication_check',
  'act_score',
]);
export type EMetric = z.infer<typeof ZMetric>;

export const ZClinicalReading = z.object({
  _id: ZObjectId,
  airtable_id: z.string().optional(),
  metric: ZMetric,
  datetime: ZDateString,
  note: z.string(),
  value: z.union([z.null(), z.string(), z.array(z.string())]),
  units: z.string().optional(),
  suverian: ZObjectId,
});
export type IClinicalReading = z.infer<typeof ZClinicalReading>;

export const ZClinicalReadingInvalidated = z.object({
  suverian: ZObjectId,
  datetime: ZDateString,
  reason: z.string(),
  clinical_readings: z.array(ZClinicalReading),
});
export type IClinicalReadingInvalidated = z.infer<
  typeof ZClinicalReadingInvalidated
>;

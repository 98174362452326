import { z } from 'zod';

import { ZObjectId } from './objectid';
import { ZAddress } from './child/address';
import { ZPhoneNumber } from './child/phoneNumber';
import { ZDateString } from './date';
import { ZPracticeOnboardingConfig } from './practice.onboarding';
import { ZPracticeSmsTemplate } from './practice.smsTemplate';
import { ZTargetAreaKeys } from './targets';

const ZPracticeEmail = z.object({
  type: z.string(),
  value: z.string(),
});
export type IPracticeEmail = z.infer<typeof ZPracticeEmail>;

const ZAccessType = z.enum([
  'service_account',
  'smartcard',
  'smartcard_prescribing',
  'access_requested',
  'issue_with_access',
  'awaiting_confirmation',
]);
export type EAccessType = z.infer<typeof ZAccessType>;

const ZPracticeAccess = z.object({
  suverian: ZObjectId,
  access_type: ZAccessType,
});
export type IPracticeAccess = z.infer<typeof ZPracticeAccess>;

export const ZPracticeAllocationType = z.enum(['manual', 'automated']);
export type EPracticeAllocationType = z.infer<typeof ZPracticeAllocationType>;

const ZPracticeAllocation = z.object({
  suverian: ZObjectId,
  allocation_type: ZPracticeAllocationType,
});

const ZIm1FeatureFlags = z.object({
  im1_enabled: z.boolean().optional(),
  im1_sms_enabled: z.boolean().optional(),
  im1_consultation_enabled: z.boolean().optional(),
  im1_sync_patient_enabled: z.boolean().optional(),
  im1_credentials_valid: z.boolean().optional(),
  im1_show_additional_consultation_fields: z.boolean().optional(),
});

const ZPracticeSmsTemplates = z.object({
  caseFindingOnboarding: z.string().optional(),
  defaultOnboarding: z.string().optional(),
});

export type IPracticeAllocation = z.infer<typeof ZPracticeAllocation>;

export const ZPractice = z.object({
  _id: ZObjectId,
  active: z.boolean(),
  test_practice: z.boolean().optional(),
  clinical_commissioning_group: ZObjectId,
  primary_care_network: ZObjectId,
  name: z.string().min(1),
  sms_sender_id: z.string().max(11).optional(),
  landing_page: z.string().optional(),
  address: ZAddress,
  phone_numbers: z.array(ZPhoneNumber),
  ehr_system: z.enum(['EMIS', 'SystmOne']),
  emis_org_code: z.string().optional(),
  access: z.array(ZPracticeAccess).default([]),
  emails: z.array(ZPracticeEmail),
  allocated_suverians: z.array(ZPracticeAllocation).default([]),
  odsCode: z.string(),
  practiceOnboardingConfig: ZPracticeOnboardingConfig.optional(),
  im1_feature_flags: ZIm1FeatureFlags.optional(),
  lastSynced: ZDateString.optional(),
  lastSyncedExtractedTargetAreas: z.array(ZTargetAreaKeys).optional(),
  // eslint-disable-next-line unicorn/no-null
  contractEndDate: ZDateString.nullable().default(null),
  smsTemplates: ZPracticeSmsTemplates.strict().optional(),
  onboardingLock: ZDateString.optional(),
});

export const ZCreatePractice = ZPractice.omit({
  _id: true,
})
  .required({
    odsCode: true,
  })
  .extend({
    address: ZAddress.optional(),
    phone_numbers: z
      .array(
        z.object({
          number: z.string().min(10),
          type: z.literal('other'),
        }),
      )
      .min(0),
    emails: z
      .array(
        z.object({
          type: z.literal('general'),
          value: z.string().email(),
        }),
      )
      .min(0),
    ehr_system: z.enum(['', 'EMIS', 'SystmOne']),
    smsTemplates: z
      .object({
        caseFindingOnboarding: ZPracticeSmsTemplate,
        defaultOnboarding: ZPracticeSmsTemplate,
      })
      .strict()
      .optional(),
  });

export type ICreatePractice = z.infer<typeof ZCreatePractice>;

export const ZPatchPractice = ZCreatePractice.pick({
  odsCode: true,
  address: true,
  phone_numbers: true,
  emails: true,
  ehr_system: true,
  emis_org_code: true,
  sms_sender_id: true,
})
  .strict()
  .partial();

export type IPatchPractice = z.infer<typeof ZPatchPractice>;

export type IPractice = z.infer<typeof ZPractice>;

export type EEHRSystem = IPractice['ehr_system'];

import { Link, Stack, Typography } from '@mui/material';

import Page from 'components/Page';
import { usePlannerViewportHeight } from 'pages/PlannerListPage/hooks/usePlannerViewportHeight';

export default function NoOnboardingConfigPage() {
  const viewportHeight = usePlannerViewportHeight();

  return (
    <Page>
      <Stack
        minHeight={viewportHeight}
        spacing="16px"
        justifyContent="center"
        alignItems="center"
        bgcolor="colors.greyLightest"
      >
        <Stack>
          <Typography variant="h3" component="h1" textAlign="center">
            We’re setting up your onboarding rules.
          </Typography>

          <Typography textAlign="center">
            Please refresh this page or check back in soon.
          </Typography>
        </Stack>

        <Link onClick={() => globalThis.location.reload()}>Refresh</Link>
      </Stack>
    </Page>
  );
}

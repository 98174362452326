import { z } from 'zod';
import { ZClinicalReading } from './child/clinicalReading';
import { ZDateString } from './date';
import { ZAppointment } from './appointment';
import { ZPastMedicalHistory } from './patient';

/** Template type */

export const reviewTemplateNonUrgentTypes = [
  'hypertension_routine_review',
  'hypertension_case_finding',
  'diabetes_case_finding',
  'diabetes_routine_review',
  'cholesterol_routine_review',
  'structured_medication_review',
  'frailty_routine_review',
  'asthma_routine_review',
] as const;
export const reviewTemplateUrgentTypes = [
  'hypertension_urgent_review',
  'hypotension_urgent_review',
  'diabetes_urgent_review',
] as const;
export const reviewTemplateTypes = [
  ...reviewTemplateNonUrgentTypes,
  ...reviewTemplateUrgentTypes,
] as const;

export const allTemplateTypes = [
  ...reviewTemplateTypes,
  'admin_note',
  'intro_call',
  'reengagement_call',
  'opt_out_call',
  'other',
] as const;

const ZTemplateType = z.enum(allTemplateTypes);
export type ETemplateType = z.infer<typeof ZTemplateType>;

/** Metric questionnaires */

const ZLifestyleQuestionnaire = z.object({
  bmi: z.string().optional(),
  weight: z.string().optional(),
  height: z.string().optional(),
  smoking: z.string().optional(),
  alcohol: z.string().optional(),
  activity: z.string().optional(),
  sodium: z.string().optional(),
  confidence: z.string().optional(),
  vaping: z.string().optional(),
  diet: z.string().optional(),
  lowSaltDietAdviceGiven: z.string().optional(),
  excessCaffeineIntake: z.string().optional(),
  caffeineIntakeNotes: z.string().optional(),
  goal: z.string().optional(),
});

export type ILifestyleQuestionnaire = z.infer<typeof ZLifestyleQuestionnaire>;

const ZBloodPressureStatistics = z.object({
  maxSystolic: z.number().nullable(),
  maxDiastolic: z.number().nullable(),
  avgSystolic: z.number().nullable(),
  avgDiastolic: z.number().nullable(),
  from: ZDateString,
  to: ZDateString,
});

const ZDiabetesInfo = z.object({
  doesHomeGlucose: z.string().optional(),
  glucoseBelow4: z.string().optional(),
  glucoseBelow2p8: z.string().optional(),
  experiencesHypos: z.string().optional(),
  symptomsDuringHypos: z.string().optional(),
  timesHelpDuringHypos: z.string().optional(),
  dateLastHypo: z.string().optional(),
  bloodSugarToTreat: z.string().optional(),
  numTimesSugarLess4: z.string().optional(),
  eatDrinkToTreat: z.string().optional(),
  checkSugarBeforeDriving: z.string().optional(),
  fastingAtTimeOfHypo: z.string().optional(),
});

/** Structured content */

export const ZPatientAttendanceOptions = z.enum([
  'present',
  'not_present',
  'patient_not_required',
]);

export const ZMedicationChangeOptions = z.enum([
  'dose_increased',
  'dose_decreased',
  'medication_added',
  'medication_removed',
  'medication_unchanged',
  'medication_changed',
  'changes_refused',
]);

const ZDiabetesCaseFindingOptions = z.enum([
  'ongoing_investigation',
  'not_diabetic',
  'newly_diagnosed_non_diabetic_hyperglycemia',
  'newly_diagnosed_type_2_diabetes',
  'existing_diagnosis_recorded_non_diabetic_hyperglycemia',
  'existing_diagnosis_recorded_type_2_diabetes',
]);

const ZHypertensionCaseFindingOptions = z.enum([
  'ongoing_investigation',
  'not_hypertensive',
  'newly_diagnosed',
  'existing_diagnosis_recorded',
]);

export const ZPatientLocationOptions = z.enum([
  'not_asked',
  'patient_is_at_home',
  'patient_is_in_another_location',
  'asked_and_refused_to_provide',
]);

export const ZDiabetesDiabeticRetinopathyScreeningOptions = z.enum([
  'accepted',
  'declined',
  'referred',
  'already_attended',
]);

export const ZDiabetesStructuredEducationProgrammeOptions = z.enum([
  'referred',
  'declined',
  'not_available',
  'not_relevant',
]);

export const ZDiabetesSelfManagementSnomedCodes = z.enum([
  '2117891000000114',
  '2115761000000117',
]);

export const ZDiabetesTreatmentPlanTypeSnomedCodes = z.enum([
  '264679015',
  '264681018',
  '264682013',
  '458512016',
  '1177721000000112',
  '2460251000000115',
  '1966321000006112',
  '1966311000006116',
]);

export const ZDiabetesControlSnomedCodes = z.enum([
  '264707013',
  '616891000006119',
  '401531012',
  '1807191000006118',
  '264711019',
  '356121018',
]);

export const ZDiabetesInteractionFields = z
  .object({
    diabetesCarePlanAgreed: z.boolean(),
    diabetesSelfManagementPlan: ZDiabetesSelfManagementSnomedCodes,
    diabetesTreatmentPlanType: ZDiabetesTreatmentPlanTypeSnomedCodes,
    diabetesRandomBloodGlucoseLevel: z.string(),
    diabetesFastingBloodGlucoseLevel: z.string(),
    diabetesControl: ZDiabetesControlSnomedCodes,
    diabetesHasSeenDietician: z.boolean(),
    diabetesBloodPressureRefused: z.boolean(),
    diabetesNewEssentialHypertension: z.boolean(),
    diabetesDiabeticRetinopathyScreening:
      ZDiabetesDiabeticRetinopathyScreeningOptions,
    diabetesStructuredEducationProgramme:
      ZDiabetesStructuredEducationProgrammeOptions,
    diabetesNonDiabeticHyperglycemiaEducation: z.boolean(),
    diabetesVehicleDriverType: z.string(),
    diabetesDrivingEducation: z.boolean(),
    diabetesDrivingAdvisedToInformDvla: z.boolean(),
    diabetesProvisionOfWrittenInformation: z.boolean(),
  })
  .partial();

export const ZCalloutShown = z.object({
  name: z.string(),
  text: z.string(),
});
export type ICalloutShown = z.infer<typeof ZCalloutShown>;

const ZGenericQuestionnaireAnswers = z.record(ZClinicalReading.shape.value);

export const ZContextQuestions = z.object({
  isVideoCall: z.boolean().optional(),
  isFailedEncounter: z.boolean().optional(),
  isPatientRepresentativePresent: z.boolean().optional(),
  patientIdentityConfirmed: z.boolean().optional(),
  patientLocation: ZPatientLocationOptions.optional(),
  patientLocationNote: z.string().optional(),
  patientAttendance: ZPatientAttendanceOptions.optional(),
  appointment: z
    .union([ZAppointment, z.literal('unplanned_appointment')])
    .optional(),
  occurredAtDatetime: ZDateString,
  calloutsShown: z.array(ZCalloutShown).optional(),
});

export const ZTemplateStructuredContent = ZContextQuestions.extend({
  templatesUsed: z.array(ZTemplateType).optional(),

  // Interaction notes
  adminNote: z.string().optional(),
  introCallNote: z.string().optional(),
  optOutCallNote: z.string().optional(),
  otherClinicalNote: z.string().optional(),
  reengagementCallNote: z.string().optional(),

  // General clinical questions
  bloodPressureStatistics: ZBloodPressureStatistics.optional(),
  pastMedicalHistory: ZPastMedicalHistory.optional(),
  lifestyleQuestionnaire: ZLifestyleQuestionnaire.optional(),
  lifestyleWeightAdvice: z
    .enum([
      'advice_given_about_weight_management',
      'weight_management_advice_declined',
    ])
    .optional(),
  lifestyleSmokingAdvice: z
    .enum(['advice_given_about_smoking', 'smoking_advice_declined'])
    .optional(),
  lifestyleDietAdvice: z
    .enum(['advice_given_about_diet', 'diet_advice_declined'])
    .optional(),
  lifestyleExerciseAdvice: z
    .enum(['advice_given_about_exercise', 'exercise_advice_declined'])
    .optional(),
  lifestyleAlcoholAdvice: z
    .enum(['advice_given_about_alcohol', 'alcohol_advice_declined'])
    .optional(),

  // Hypertension specific questions
  hypertensionHistory: z.string().optional(),
  hypertensionPlan: z.string().optional(),
  hypertensionMedicationChanges: ZMedicationChangeOptions.optional(),
  hypertensionMedicationChangeDetail: z.string().optional(),
  hypertensionResistant: z.boolean().optional(),

  // Hypotension specific questions
  hypotensionHistory: z.string().optional(),
  hypotensionPlan: z.string().optional(),
  hypotensionMedicationChanges: ZMedicationChangeOptions.optional(),
  hypotensionMedicationChangeDetail: z.string().optional(),
  hypotensionResistant: z.boolean().optional(),

  // Red flags safety netting
  safetyNettingForRedSelectedFlags: z.boolean().optional(),

  // Hypertension legacy red flags to be migrated
  // Remove in https://linear.app/suvera/issue/GREEN-240/migrate-old-red-flag-fields-to-new-names
  redFlagConfusedEpisodes: z.boolean().optional(), // renaming to redFlagLethargyDrowsinessOrConfusion
  redFlagIrregularHeartBeat: z.boolean().optional(), // renaming to redFlagPalpitations
  redFlagDifficultyBreathing: z.boolean().optional(), // renaming to redFlagShortnessOfBreath
  redFlagVisualProblems: z.boolean().optional(), // renaming to redFlagBlurredVision

  // Red flags
  redFlagAbdominalPain: z.boolean().optional(),
  redFlagAcuteOnsetLimbSymptoms: z.boolean().optional(),
  redFlagAssistanceToManageHypos: z.boolean().optional(),
  redFlagBloodInUrine: z.boolean().optional(),
  redFlagBlurredVision: z.boolean().optional(),
  redFlagCapillaryBloodGlucoseLess2p8: z.boolean().optional(),
  redFlagChestPain: z.boolean().optional(),
  redFlagDizziness: z.boolean().optional(),
  redFlagDrySkinAndMouth: z.boolean().optional(),
  redFlagEvidenceOfKetones: z.boolean().optional(),
  redFlagFastDeepOrDifficultBreathing: z.boolean().optional(),
  redFlagFlushedFace: z.boolean().optional(),
  redFlagFruitySmellingBreath: z.boolean().optional(),
  redFlagLethargyDrowsinessOrConfusion: z.boolean().optional(),
  redFlagMuscleStiffnessOrAches: z.boolean().optional(),
  redFlagNauseaAndVomiting: z.boolean().optional(),
  redFlagNosebleeds: z.boolean().optional(),
  redFlagPalpitations: z.boolean().optional(),
  redFlagPersistentGlucoseMore15: z.boolean().optional(),
  redFlagSevereHeadache: z.boolean().optional(),
  redFlagShortnessOfBreath: z.boolean().optional(),
  redFlagUrinatingLotMoreThanUsual: z.boolean().optional(),
  redFlagVeryThirsty: z.boolean().optional(),
  redFlagFalls: z.boolean().optional(),
  redFlagWeaknessNumbness: z.boolean().optional(),
  redFlagTemperature: z.boolean().optional(),
  redFlagCurrentlyUnwell: z.boolean().optional(),

  // Hypertension case finding questions
  hypertensionCaseFinding: ZHypertensionCaseFindingOptions.optional(),

  // Diabetes case finding questions
  diabetesCaseFinding: ZDiabetesCaseFindingOptions.optional(),
  leicesterRiskScore: z.number().optional(),
  nhsDiabetesPreventionProgrammeReferral: z.string().optional(),
  nhsDiabetesPreventionProgrammeStarted: z.string().optional(),

  // Blood pressure specific questions
  bloodPressureHistory: z.string().optional(),
  bloodPressurePlan: z.string().optional(),
  bloodPressureMedicationChanges: ZMedicationChangeOptions.optional(),
  bloodPressureMedicationChangeDetail: z.string().optional(),

  // Urgent hypertension questions
  bloodPressureReadingTechniqueChecked: z.boolean().optional(),
  recentExercise: z.boolean().optional(),
  recentExcessiveCaffeineIntake: z.boolean().optional(),

  // Urgent hypotension questions
  hypoBloodPressureReadingTechniqueChecked: z.boolean().optional(),
  hypoRecentExercise: z.boolean().optional(),

  // Medication changes questions:
  medicationComplianceChecked: z.boolean().optional(),
  medicationComplianceLevel: z.enum(['good', 'partial', 'poor']).optional(),
  allergiesConfirmed: z.boolean().optional(),
  sideEffectsDiscussed: z.boolean().optional(),
  prescriptionIssued: z.boolean().optional(),

  // Intro call questions
  introCallConfirmedPtPhoneNumber: z.boolean().optional(),

  // Actions:
  referralCompletedToGp: z.boolean().optional(),
  referralCompletedToHospital: z.boolean().optional(),
  referralCompletedToEmergency: z.boolean().optional(),
  referralCompletedToDMEducation: z.boolean().optional(),
  referralCompletedToPreDiabetesEducation: z.boolean().optional(),

  // Investigations:
  investigateAmbulatoryBloodPressure: z.boolean().optional(),
  investigateBloodTest: z.boolean().optional(),
  investigateUrineTest: z.boolean().optional(),
  investigateFootCheck: z.boolean().optional(),
  investigatePhysicalExamination: z.boolean().optional(),
  investigateOther: z.boolean().optional(),

  // Advice given
  adviceGivenVaccinations: z.boolean().optional(),
  adviceGivenDiabeticEyeScreening: z.boolean().optional(),

  // Pre-Diabetes
  preDiabetesHistory: z.string().optional(),
  preDiabetesPlan: z.string().optional(),
  preDiabetesMedicationChanges: ZMedicationChangeOptions.optional(),
  preDiabetesMedicationChangeDetail: z.string().optional(),

  // Diabetes
  diabetesHistory: z.string().optional(),
  diabetesPlan: z.string().optional(),
  diabetesMedicationChanges: ZMedicationChangeOptions.optional(),
  diabetesMedicationChangeDetail: z.string().optional(),

  // Urgent diabetes
  medicationsRecentlyAltered: z.boolean().optional(),
  previousDkaHistory: z.boolean().optional(),

  // Diabetes questionnaire
  diabetesQuestionnaire: ZDiabetesInfo.optional(),

  // CVD risk
  qRisk3: z.number().optional(),
  qRisk3Declined: z.boolean().optional(),

  // Cholesterol
  cholesterolHistory: z.string().optional(),
  cholesterolPlan: z.string().optional(),
  cholesterolMedicationChanges: ZMedicationChangeOptions.optional(),
  cholesterolMedicationChangeDetail: z.string().optional(),
  cholesterolLipidsTypeOfPrevention: z.string().optional(),
  cholesterolResultExceptions: z.string().optional(),
  cholesterolStatinSpecificLipidloweringTherapyExceptions: z
    .array(z.string())
    .optional(),
  cholesterolBempedoicAcidSpecificLipidloweringTherapyExceptions: z
    .array(z.string())
    .optional(),
  cholesterolEzetimibeSpecificLipidloweringTherapyExceptions: z
    .array(z.string())
    .optional(),
  cholesterolInclisiranSpecificLipidloweringTherapyExceptions: z
    .array(z.string())
    .optional(),
  cholesterolExceptions: z.array(z.string()).optional(),

  // Frailty
  frailtyFallsAssessmentReviewed: z.boolean().optional(),
  frailtySummaryOfNeedsReviewed: z.boolean().optional(),
  frailtyGoalsAndPrioritiesReviewed: z.boolean().optional(),
  frailtyCarePlanAgreed: z.boolean().optional(),
  frailtyAnticipatoryCarePlanAgreed: z.boolean().optional(),
  frailtyFocusedMedicationReviewPerformed: z.boolean().optional(),
  frailtyDementiaAssessmentPerformed: z
    .enum(['yes', 'no', 'not_required'])
    .optional(),
  frailtyCarePlanSharedWithPatient: z
    .enum(['yes', 'no', 'declined'])
    .optional(),
  frailtyNote: z.string().optional(),

  // SMR
  smrMedicationIndicationsChecked: z.boolean().optional(),
  smrMedicationsLinkedToConditions: z.boolean().optional(),
  smrPatientUnderstandsWhyTheyTakeMedications: z
    .enum(['yes', 'no', 'not_asked'])
    .optional(),
  smrPatientIsAbleToManageTheirOwnMedication: z
    .enum(['yes', 'no', 'not_asked'])
    .optional(),
  smrFactorsAffectingMedicationCompliance: z.string().optional(),
  smrMedicationInteractionsChecked: z.boolean().optional(),
  smrMedicationCostEffectivenessChecked: z.boolean().optional(),
  smrMedicationMonitoringRequirementsChecked: z.boolean().optional(),
  smrOTCMedicationsChecked: z.boolean().optional(),
  smrBloodTestResultsChecked: z.boolean().optional(),
  smrRepeatMedicationsReviewedSynchronisedAndReauthorised: z
    .boolean()
    .optional(),
  smrFourOrMoreMedications: z.boolean().optional(),
  smrMaxToleratedAntihypertensiveTherapy: z.boolean().optional(),
  smrHistory: z.string().optional(),
  smrMedicationChangeDetail: z.string().optional(),
  smrPlan: z.string().optional(),
  smrMedicationChanges: ZMedicationChangeOptions.optional(),

  // Asthma Questionnaire
  asthmaDailyLife: z.string().optional(),
  asthmaShortnessOfBreath: z.string().optional(),
  asthmaSymptoms: z.string().optional(),
  asthmaInhalerMedication: z.string().optional(),
  asthmaControl: z.string().optional(),
  asthmaTriggers: z.array(z.string()).optional(),
  asthmaExacerbations: z.string().optional(),
  asthmaMedicationUnderstanding: z.string().optional(),
  asthmaMedicationCheck: z.string().optional(),

  // General Asthma Information
  asthmaControlReview: z.string().optional(),
  asthmaInhalerTechnique: z.string().optional(),
  asthmaInhalerCompliance: z.string().optional(),
  asthmaRescueMedications: z.string().optional(),
  asthmaMedicationDetailsEhr: z.string().optional(),
  asthmaCarePlan: z.string().optional(),
  asthmaHospitalVisits: z.string().optional(),
  asthmaHousingConditions: z.string().optional(),

  // Inhaler devices and technique
  asthmaInhalerDevicesTechnique: z.string().optional(),
  asthmaInhalerTechniqueAssessment: z.string().optional(),
  asthmaInhalerCheckingDeviceUsed: z.boolean().optional(),
  asthmaDryPowderInhalerNotIndicated: z.boolean().optional(),
  asthmaCurrentInhalerTechniqueShown: z.boolean().optional(),
  asthmaInhalerTechniqueShownByClinician: z.boolean().optional(),
  asthmaAdviceForFrequentUse: z.boolean().optional(),
  asthmaAdviceForOralCare: z.boolean().optional(),
  asthmaAdviceToRecycleInhaler: z.boolean().optional(),
  asthmaSpacer: z.string().optional(),
  asthmaIcsUse: z.string().optional(),
  asthmaBronchodilator: z.string().optional(),
  asthmaRelieverInhalerPrescribedPerYear: z.string().optional(),
  asthmaAdviceOnSpacerGiven: z.boolean().optional(),
  asthmaWashMonthlyInWarmSoapyWater: z.boolean().optional(),
  asthmaPeakFlowMeter: z.string().optional(),
  asthmaPefrTechniqueAssessment: z.string().optional(),
  asthmaPefrTechniqueEducation: z.boolean().optional(),

  // Asthma exacerbation
  asthmaIsThePatientExperiencingAcuteAsthmaAttack: z.string().optional(),
  asthmaNotes: z.string().optional(),
  asthmaPositiveVariability: z.boolean().optional(),
  asthmaNegativeVariability: z.boolean().optional(),
  asthmaPEFRscore: z.string().optional(),
  asthmaMildLifeThreateningExacerbation: z.string().optional(),
  asthmaRelieverInhalerTherapyAsPerCarePlanAdviceGiven: z.boolean().optional(),
  asthmaPEFRMonitoringUsingDiary: z.boolean().optional(),

  asthmaHistory: z.string().optional(),
  asthmaPlan: z.string().optional(),
  asthmaMedicationChanges: ZMedicationChangeOptions.optional(),
  asthmaMedicationChangeDetail: z.string().optional(),

  asthmaQuestionnaire: ZGenericQuestionnaireAnswers.optional(),
}).merge(ZDiabetesInteractionFields);

export type TemplateStructuredContent = z.infer<
  typeof ZTemplateStructuredContent
>;

import { Box, Button, Stack, Typography } from '@mui/material';
import { ROUTES } from 'App/routes';
import { useNavigate } from 'react-router-dom';

const handleRefresh = () => {
  globalThis.location.reload();
};

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleNavigateToHomepage = () => {
    navigate(ROUTES.homepage);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
      bgcolor="colors.greyLightest"
    >
      <Stack alignItems="center" minWidth="320px" spacing="8px">
        <Typography variant="h3" component="h1">
          Page not found
        </Typography>

        <Stack direction="row" spacing="8px" width="100%">
          <Button
            fullWidth
            variant="outlined"
            sx={{ minWidth: '160px' }}
            onClick={handleNavigateToHomepage}
          >
            Back to homepage
          </Button>

          <Button
            fullWidth
            variant="contained"
            sx={{ minWidth: '160px' }}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;

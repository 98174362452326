import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { ReactComponent as Confused } from './confused.svg';

interface GenericErrorProps {
  errorText?: string;
}

export const GenericError = ({
  errorText = 'Oops! Something went wrong',
}: GenericErrorProps) => {
  return (
    <Stack alignItems="center" flexGrow="1" pt="100px" pb="100px">
      <Confused
        height="105"
        width="63"
        title="generic error with confused face"
      />
      <Typography variant="h2" component="h1" mt="20px">
        {errorText}
      </Typography>
      <Typography>Please refresh the page or try again later</Typography>
      <Box pt="24px">
        <Button
          variant="contained"
          onClick={() => globalThis.location.reload()}
          color="secondary"
        >
          Refresh page
        </Button>
      </Box>
    </Stack>
  );
};

export const ErrorBoundaryFallbackPage: FC = () => {
  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <GenericError />
    </Box>
  );
};
